import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useMemo, useState } from 'react';
import ReactModal, { Styles } from 'react-modal';
import { WHITE } from '../../constants/colors';
import { INNER_ELEMENT_WIDTH } from '../../constants/layout';
import { contactData, contactSnsData } from '../../data/contact';
import { UrbaneFont } from '../../styles/common';
import { getDesktopStyle, showOnDesktopStyle, showOnMobileStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';
import { isMobile } from '../../utils/userAgent';
import MobileLineButton from '../common/MobileLineButton';
import ContactModal from './ContactModal';

if (typeof window !== 'undefined') {
	ReactModal.setAppElement('body');
}

const ContactListItem = () => {
	const [showModal, setShowModal] = useState(false);
	const [textList, setTextList] = useState();

	const clickHandler = (param: any) => {
		setShowModal(true);
		setTextList(param);
	};

	const modalClick = (data: any) => {
		setShowModal(data);
	};

	const ModalStyle: Styles = useMemo(
		() =>
			isMobile()
				? {
						content: {
							position: 'absolute',
							width: 'calc(100vw - 48px)',
							height: 'calc(90vh - 48px)',
							top: '55%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							padding: 0,
						},
				  }
				: {
						content: {
							position: 'absolute',
							width: pxToRem(INNER_ELEMENT_WIDTH),
							height: pxToRem(593),
							top: '60%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							padding: 0,
						},
				  },
		[],
	);

	return (
		<>
			<ContactWrapper>
				{contactData.map((data) => (
					<ContactList key={data.id}>
						<ContactListTitle>{data.title}</ContactListTitle>
						<ContactListDescription>
							{data.description}
							{data.map && (
								<LinkIcon href={data.map} target="_blank" aria-label={`자세한 지도 보기`}>
									<svg
										width="11"
										height="11"
										viewBox="0 0 15 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										style={{ display: 'inline-block' }}
									>
										<path d="M5 1H14V10" stroke={WHITE} strokeWidth="2" />
										<path d="M14 1L1 14" stroke={WHITE} strokeWidth="2" />
									</svg>
								</LinkIcon>
							)}
							{data.map && (
								<>
									<ContactTelPc>{data.tel}</ContactTelPc>
									<ContactDescPc>{data.desc}</ContactDescPc>
									<ContactTelMo>{data.tel}</ContactTelMo>
									<ContactDescMo>{data.desc}</ContactDescMo>
								</>
							)}
						</ContactListDescription>
						{data.link && (
							<>
								<ContactListLinkDesktop>
									{data.link}
									{data.linkId ? (
										<ContactListLinkId> {data.linkId}</ContactListLinkId>
									) : (
										<Icon onClick={() => clickHandler(data)} aria-label={`${data.title}로 메일 보내기`}>
											<svg
												width="11"
												height="11"
												viewBox="0 0 15 15"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
												style={{ display: 'inline-block' }}
											>
												<path d="M5 1H14V10" stroke={WHITE} strokeWidth="2" />
												<path d="M14 1L1 14" stroke={WHITE} strokeWidth="2" />
											</svg>
										</Icon>
									)}
									<ContactTelPc>{data.tel}</ContactTelPc>
									<ContactDescPc>{data.desc}</ContactDescPc>
								</ContactListLinkDesktop>
								<ContactListLinkMobile>
									<ContectMobileLink>
										{data.linkId && data.link ? (
											<ContectKakaoMobile>
												<ContactListLinkId>{data.link}{` ${data.linkId}`}</ContactListLinkId>
											</ContectKakaoMobile>
										) : (
											<>{data.link}</>
										)}
									</ContectMobileLink>
									<ContactTelMo>{data.tel}</ContactTelMo>
									<ContactDescMo>{data.desc}</ContactDescMo>
									{data.linkId && data.link ? (
										<></>
									) : (
										<ContectKakaoMobile>
											<MobileLineButton text="문의하기" onClick={() => clickHandler(data)} />
										</ContectKakaoMobile>
									)}
								</ContactListLinkMobile>
							</>
						)}
					</ContactList>
				))}
			</ContactWrapper>
			<ContactSnsWrapper>
				{contactSnsData.map((data) => (
					<ContactStyledLink
						key={data.id}
						href={data.link}
						target="_blank"
						rel="noopener noreferrer"
						aria-label={`SNS로 이동하기`}
					>
						<SnsIcon src={data.icon} alt="" />
					</ContactStyledLink>
				))}
			</ContactSnsWrapper>
			<ReactModal
				isOpen={showModal}
				contentLabel={'ContactUsModal'}
				shouldCloseOnOverlayClick={true}
				onRequestClose={() => setShowModal(false)}
				preventScroll={true}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(0, 0, 0, 0.7)',
						zIndex: 10,
					},
					...ModalStyle,
				}}
			>
				<ContactModal text={textList} parentFunction={modalClick} />
			</ReactModal>
		</>
	);
};

export default ContactListItem;

const ContactWrapper = styled.ul`
	display: grid;
	grid-row-gap: ${pxToRem(70)};
	margin-top: ${pxToRem(56)};
	margin-bottom: ${pxToRem(44)};

	${getDesktopStyle(css`
		margin-top: ${pxToRem(82)};
		grid-row-gap: ${pxToRem(44)};
		margin-bottom: ${pxToRem(99)};
		grid: repeat(3, 1fr) / repeat(3, auto);
		grid-row-gap: ${pxToRem(82)};
		justify-content: space-between;
		/* grid-column-gap: ${pxToRem(130)}; */
	`)}
`;

const ContactList = styled.li`
	:nth-of-type(7),
	:nth-of-type(8) {
		/*display: none;*/
	}

	${getDesktopStyle(css`
		:nth-of-type(7),
		:nth-of-type(8) {
			display: block;
		}

		:nth-of-type(7) {
			grid-row: 3 / 4;
			grid-column: 1 / 2;
		}

		:nth-of-type(8) {
			grid-row: 3 / 4;
			grid-column: 2 / 3;
		}
	`)}
`;

const ContactListTitle = styled.p`
	${UrbaneFont};
	font-size: ${pxToRem(20)};
	font-weight: 700;

	${getDesktopStyle(css`
		font-size: ${pxToRem(22)};
	`)}
`;

const ContectMobileLink = styled.p`
	${UrbaneFont};
	font-size: ${pxToRem(15)};
	font-weight: 600;

	${getDesktopStyle(css`
		display:none;
	`)}
`;
const ContactTelPc = styled.p`
	${UrbaneFont};
	font-size: ${pxToRem(15)};
	font-weight: 600;
	display:none;
	margin-top: 3px;

	${getDesktopStyle(css`
		font-size: ${pxToRem(15)};
		font-weight: 500;
		display:block;
	`)}
`;
const ContactDescPc = styled.p`
	${UrbaneFont};
	font-size: ${pxToRem(15)};
	font-weight: 600;
	display:none;

	${getDesktopStyle(css`
		font-size: ${pxToRem(15)};
		padding-top: ${pxToRem(5)};
		display:block;
	`)}
`;
const ContactTelMo = styled.p`
	${UrbaneFont};
	font-size: ${pxToRem(15)};
	font-weight: 600;

	${getDesktopStyle(css`
		display:none;
	`)}
`;
const ContactDescMo = styled.p`
	${UrbaneFont};
	font-size: ${pxToRem(15)};
	font-weight: 600;

	${getDesktopStyle(css`
		display:none;
	`)}
`;

const ContactListDescription = styled.p`
	padding: ${pxToRem(8)} 0 ${pxToRem(20)};
	font-weight: 500;

	${getDesktopStyle(css`
		padding: ${pxToRem(8)} 0 ${pxToRem(16)};
		font-size: ${pxToRem(15)};
	`)}
`;

const ArrowIconStyle = css`
	margin-left: ${pxToRem(8)};
	cursor: pointer;
`;

const LinkIcon = styled.a`
	${ArrowIconStyle};
`;

const Icon = styled.button`
	${ArrowIconStyle};
`;

const ContactListLinkDesktop = styled.p`
	${showOnDesktopStyle};
	${getDesktopStyle(css`
		position: relative;
		${UrbaneFont};
		font-weight: 500;
		font-size: ${pxToRem(15)};
		padding-bottom: ${pxToRem(5)};

		&:after {
			position: absolute;
			left: 0;
			top: ${pxToRem(-8)};
			display: block;
			width: ${pxToRem(42)};
			height: 1px;
			background: ${WHITE};
			content: '';
		}
	`)}
`;

const ContactListLinkMobile = styled.div`
	${showOnMobileStyle}
	${UrbaneFont};
	font-weight: 600;
	font-size: ${pxToRem(15)};
	position:relative;
	&:after {
			position: absolute;
			left: 0;
			top: ${pxToRem(-9)};
			display: block;
			width: ${pxToRem(42)};
			height: 1px;
			background: ${WHITE};
			content: '';
		}
`;

const ContactListLinkId = styled.em`
	${UrbaneFont};
	font-style: normal;
	font-weight: 400;
	font-size: ${pxToRem(15)};
`;

const ContactSnsWrapper = styled.div``;

const ContectKakaoMobile = styled.p`
	${UrbaneFont};
	font-style: normal;
	font-weight: 600;
	font-size: ${pxToRem(15)};
	margin-top: ${pxToRem(15)};
`;

const SnsIcon = styled.img`
	width: ${pxToRem(46)};

	${getDesktopStyle(css`
		width: ${pxToRem(36)};
	`)}
`;

const ContactStyledLink = styled.a`
	display: inline-block;
	&:first-of-type {
		margin-right: ${pxToRem(25)};
	}

	${getDesktopStyle(css`
		&:first-of-type {
			margin-right: ${pxToRem(16)};
		}
	`)}
`;
